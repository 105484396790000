import { MAIN_CONFIG } from '../../config/main';
import cookies from 'next-cookies';
import KaleidoscopeAPI from './KaleidoscopeAPI';
const API = new KaleidoscopeAPI({});
const { PAGE_TYPES, USER_CONTEXT } = MAIN_CONFIG;

/**
 * This returns the data for the page depending on the current user context
 * and the page requested.
 * @param {string} pageType
 * @param {string} userContext
 * @param {string} authToken
 * @param {object} routerContext
 * @param {string} currentAccountId
 * @returns {Promise<{scholarshipId: *}>|{}|Promise<{}>|*}
 */
export default ({ pageType, userContext, authToken, routerContext, currentAccountId, authAdminToken }) => {
    switch (pageType) {
        case PAGE_TYPES.CALLBACK:
        case PAGE_TYPES.DONOR.HOME:
        case PAGE_TYPES.DONOR.ADMIN:
        case PAGE_TYPES.DONOR.DONATIONS:
        case PAGE_TYPES.RESET_PASSWORD:
        case PAGE_TYPES.ACCOUNT_SWITCH:
        case PAGE_TYPES.ROLE_SWITCH:    
        case PAGE_TYPES.LOGOUT_CONFIRM:
        case PAGE_TYPES.APPLICANT.TASKS:
        case PAGE_TYPES.APPLICANT.SAVED:
        case PAGE_TYPES.APPLICANT.APPLICATIONS:
        case PAGE_TYPES.APPLICANT.PROFILE: 
        case PAGE_TYPES.APPLICANT.DONORS:
        case PAGE_TYPES.APPLICANT.DISBURSEMENTS:
        case PAGE_TYPES.APPLICANT.RESOURCES:
        case PAGE_TYPES.APPLICANT.GRADEBOOK:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getHomePageDataForDonor({ authToken, currentAccountId });
                case USER_CONTEXT.REVIEWER:
                    return getHomePageDataForReviewer({ authToken, currentAccountId });
                case USER_CONTEXT.RECOMMENDER:
                case USER_CONTEXT.NGB:
                case USER_CONTEXT.SCHOOLCOUNSELLOR:
                case USER_CONTEXT.ENDORSEDUSER:
                case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                case USER_CONTEXT.VERIFIER:
                    return getHomePageDataForRecommender({ authToken })
                case USER_CONTEXT.APPLICANT:
                case USER_CONTEXT.TEAMMEMBER:
                    return getPageDataForApplicant({ authToken, currentAccountId, routerContext });
                case USER_CONTEXT.LOGGED_OUT:
                    return getAccountDetails({ routerContext })
                case USER_CONTEXT.UNIVERSITYSTAFF:
                case USER_CONTEXT.HSSTAFF:
                case USER_CONTEXT.COLLEGESTAFF:
                case USER_CONTEXT.DETROITSF:
                    return getHomePageDataForUniversityStaff({ authToken, currentAccountId, userContext });
                case USER_CONTEXT.ADMIN:
                    return getHomePageAdmin({ authAdminToken });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.ACCOUNT:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getAccountPageDataForDonor({ authToken, currentAccountId });
                case USER_CONTEXT.REVIEWER:
                    return getAccountPageDataForReviewer({ authToken, currentAccountId });
                case USER_CONTEXT.RECOMMENDER:
                case USER_CONTEXT.NGB:
                case USER_CONTEXT.SCHOOLCOUNSELLOR:
                case USER_CONTEXT.ENDORSEDUSER:
                case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                case USER_CONTEXT.VERIFIER:
                    return getHomePageDataForRecommender({ authToken })
                case USER_CONTEXT.UNIVERSITYSTAFF:
                case USER_CONTEXT.HSSTAFF:
                case USER_CONTEXT.COLLEGESTAFF:
                case USER_CONTEXT.DETROITSF:
                    return getHomePageDataForUniversityStaff({ authToken, currentAccountId, userContext });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.SCHOLARSHIPS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getScholarshipPageDataForDonor({ authToken, currentAccountId });
                case USER_CONTEXT.REVIEWER:
                    return getScholarshipPageDataForReviewer({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.NEW_SCHOLARSHIP:
        case PAGE_TYPES.DONOR.APPLICATION_UPDATE:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getNewScholarshipPageDataForDonor({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.RECOMMENDATION_QUESTION_UPDATE:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getRecommendationPageDataForRecommender({ authToken, routerContext, currentAccountId, pageType });
                default:
                if (authToken){
                    return getProfileHerokuRole(authToken)
                }
                return {};
        }

        case PAGE_TYPES.DONOR.EDIT_SCHOLARSHIP:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getEditScholarshipPageDataForDonor({ authToken, currentAccountId, routerContext });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    return {};
            }
        case PAGE_TYPES.DONOR.PREVIEW_SCHOLARSHIP:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getEditScholarshipPageDataForDonor({ authToken, currentAccountId, routerContext });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.APPLICATIONS:
        case PAGE_TYPES.DONOR.SCHOLARSHIP_REVIEWERS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getApplicationPageDataForDonor({ authToken, routerContext, currentAccountId });
                case USER_CONTEXT.REVIEWER:
                    return getApplicationPageDataForReviewer({ authToken, routerContext, currentAccountId });
                case USER_CONTEXT.RECOMMENDER:
                case USER_CONTEXT.NGB:
                case USER_CONTEXT.SCHOOLCOUNSELLOR:
                case USER_CONTEXT.ENDORSEDUSER:
                case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                case USER_CONTEXT.VERIFIER:
                    return getHomePageDataForRecommender({ authToken })
                case USER_CONTEXT.APPLICANT:
                case USER_CONTEXT.TEAMMEMBER:
                    return getPageDataForScholarship({ authToken, currentAccountId, routerContext });
                case USER_CONTEXT.LOGGED_OUT:
                    return getAccountDetailWithScholarship({ routerContext })
                case USER_CONTEXT.ADMIN:
                    return getHomePageAdmin({ authAdminToken });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
        }

        case PAGE_TYPES.REVIEWER.APPLICATION_DETAILS:
        // case PAGE_TYPES.REVIEWER.IFRAME_APPLICATION_DETAILS:
            switch (userContext) {
                case USER_CONTEXT.REVIEWER:
                    return getApplicationDetailsPageDataForReviewer({ authToken, routerContext, currentAccountId });
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getApplicationDetailsPageDataForDonor({ authToken, routerContext, currentAccountId });
                case USER_CONTEXT.UNIVERSITYSTAFF:
                case USER_CONTEXT.HSSTAFF:
                case USER_CONTEXT.COLLEGESTAFF:
                case USER_CONTEXT.DETROITSF:
                    return getStaffApplicationSummaryPageData({ authToken, routerContext, userContext })
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }
        case PAGE_TYPES.REVIEWER.IFRAME_APPLICATION_DETAILS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                case USER_CONTEXT.REVIEWER:
                    return getApplicationDetailsPageDataForReviewerIFrame({ authToken, routerContext, userContext });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.APPLICANTS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR: {
                    return getApplicantsPageDataForDonor({ authToken, currentAccountId });
                }
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.PEOPLE:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getPeoplePageDataForDonor({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    return {};
            }

        case PAGE_TYPES.DONOR.REPORTS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getReportsPageDataForDonor({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }
        
        case PAGE_TYPES.DONOR.DISBURSEMENTS:
            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getDisbursementPageDataForDonor({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.DONOR.MY_TASK:
        case PAGE_TYPES.DONOR.COMMUNICATIONS:

            switch (userContext) {
                case USER_CONTEXT.DONOR:
                case USER_CONTEXT.SUPERDONOR:
                    return getHomePageDataForDonor({ authToken, currentAccountId });
                case USER_CONTEXT.REVIEWER:
                    return getHomePageDataForReviewer({ authToken, currentAccountId });
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.RECOMMENDER.RECOMMENDATION:

            switch (userContext) {
                case USER_CONTEXT.RECOMMENDER:
                case USER_CONTEXT.NGB:
                case USER_CONTEXT.SCHOOLCOUNSELLOR:
                case USER_CONTEXT.ENDORSEDUSER:
                case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                case USER_CONTEXT.VERIFIER:
                    return getRecommendationPageDataForRecommender({ authToken, routerContext })
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.APPLICATION.APPLICATION:
            switch (userContext) {
                case USER_CONTEXT.LOGGED_OUT:
                    return getApplicationData({ authToken, routerContext })
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }
        case PAGE_TYPES.APPLICANT.APPLY:
            switch (userContext) {
                case USER_CONTEXT.APPLICANT:
                case USER_CONTEXT.TEAMMEMBER:
                    return getPageDataForApplicant({ authToken, currentAccountId, routerContext })
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }

        case PAGE_TYPES.SSO_AUTHENTICATION:
            return getTokenFromSSOCode({ routerContext })

        case PAGE_TYPES.APPLICANT.RESUME:
            switch (userContext) {
                case USER_CONTEXT.APPLICANT:
                case USER_CONTEXT.TEAMMEMBER:
                    return getApplicationResumePageData({ authToken, routerContext })
                default:
                    // TODO: HANDLE WHEN USER DOES NOT HAVE ACCESS TO ROUTE
                    if (authToken){
                        return getProfileHerokuRole(authToken)
                    }
                    return {};
            }
                // ...await API.fetchApplicationDetails({ token: authToken, accountId, applicationId, scholarshipId, userContext: USER_CONTEXT.REVIEWER })
        case PAGE_TYPES.APPLICANT.SCHOLARSHIPS:
            // TODO: Add logic for fetching scholarship before page load
    }
};

/**
 * Fetches the account data for Donor.
 * @returns {Promise<{accountId: *, accounts: ({errorMessage: *, error: boolean}|*)}>}
 */
const getAccountInfo = async (authToken, userContext, currentAccountId) => {
    const { accounts, error } = await API.fetchAccounts({ token: authToken, userContext });
    if(error === "Not Authorized"){
        return { statusCode: 503 }
    }
    let account;

    // If we have a currentAccountId context, try to use that account
    // Otherwise, just use the first account
    if (!currentAccountId) {
        account = accounts ? accounts.length > 0 ? accounts[0] : {} : {};
    } else {
        account = accounts ? accounts.length >0 ? accounts.find(a => a.sfid === currentAccountId) : {} : {};
    }
    account = account || accounts.length >0 && accounts[0];
    const accountId = account.sfid || currentAccountId || '';


    return { accounts, account, accountId, statusCode: 200 }
};

/**
 * Return notifications for current user.
 * @param {string} authToken
 * @returns {Promise<*>}
 */
const getNotifications = async (authToken) => {
    // const { notifications, error } = await API.fetchNotifications({ token: authToken  });
    // if(error === "Not Authorized"){
    //     return {notifications: [], statusCode: 503 }
    // }
    return {notifications: [], statusCode: 200};
    // return {notifications, statusCode: 200};
}

/**
 * Gets the global data for a user.
 * @param {string} authToken
 * @returns {Promise<{notifications: *}>}
 */
const getGlobalData = async (authToken) => {
    const {notifications} = await getNotifications(authToken);
    return {
        ...notifications
    }
}

/**
 * Gets base data for a Donor.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getDonorData = async (authToken, currentAccountId) => {
    const data = await Promise.all([
        getGlobalData(authToken),
        getAccountInfo(authToken, USER_CONTEXT.DONOR, currentAccountId),
        getProfileHerokuRole(authToken)
    ]);

    return {
        ...data[0],
        ...data[1],
        ...data[2]
    }
}

/**
 * Gets base data for a Reviewer.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{accountId: *, accounts: ({errorMessage: *, error: boolean}|*), notifications: *}>}
 */
const getReviewerData = async (authToken, currentAccountId) => {
    const data = await Promise.all([
        getGlobalData(authToken),
        getAccountInfo(authToken, USER_CONTEXT.REVIEWER, currentAccountId),
        getProfileHerokuRole(authToken)
    ]);
    return {
        ...data[0],
        ...data[1],
        ...data[2]
    }
}

/**
 * Fetch data for a Donor's Scholarship page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getScholarshipPageDataForDonor = async ({ authToken, currentAccountId = '' }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData, ...await API.fetchScholarships({ token: authToken , accountId }) };
}

/**
 * Fetch data for a Reviewer's scholarship page.
 */
const getScholarshipPageDataForReviewer = () => {
    // TODO
}

/**
 * Fetch data for a Loggedout Home page.
 * @returns {Promise<{}>}
 */
const getAccountDetails = async ({ routerContext }) => {
    let account = {}
    const { indexId } = routerContext.query;
    var marketPlace = cookies(routerContext).marketPlace;
    if(indexId || marketPlace) {
        account = await API.fetchAccountData({ marketPlace: (indexId || marketPlace) })
    }
    return account
}

/**
 * Fetch data for a Loggedout Home page.
 * @returns {Promise<{}>}
 */
 const getAccountDetailWithScholarship = async ({ routerContext }) => {
    let account = {}
    const { indexId, scholarshipId } = routerContext.query;
    var marketPlace = cookies(routerContext).marketPlace;
    if(indexId || marketPlace) {
        account = await API.fetchAccountData({ marketPlace: (indexId || marketPlace) })
    }
    let scholarshipDetail = await API.fetchScholarshipById({ token: '', scholarshipId });
    return { account, scholarshipDetail };
}

/**
 * Fetch data for a Donor's Home page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getHomePageDataForDonor = async ({ authToken, currentAccountId = '' }) => {

    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;
    // TODO: Replace with proper Donor Home API data fetch
    const data = await Promise.all([
        // API.fetchTasks({ token: authToken, accountId }),
        API.fetchAccountStats({ token: authToken, accountId })
    ]);
    return { ...accountData, ...data[0] };
}

/**
 * Fetch data for a Donor's Home page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getMyTaskPageDataForDonor = async ({ authToken, currentAccountId = '' }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    // TODO: Replace with proper Donor Home API data fetch
    return {
        ...accountData,
        ...await API.fetchTasks({ token: authToken, accountId })
    };
}

/**
 * Fetch data for a Reviewer's home page
 * @param {string} authToken
 * @param {string} currentAccountId
 */
const getHomePageDataForReviewer = async ({ authToken, currentAccountId }) => {
    const accountData = await getReviewerData(authToken, currentAccountId);
    const { accountId } = accountData;
    return {
        ...accountData,
        ...await API.fetchScholarships({ token: authToken, accountId, userContext: USER_CONTEXT.REVIEWER })
    };
}

/**
 * Fetch data for a Donor's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {object} routerContext
 * @param {string} currentAccountId
 */
const getApplicationPageDataForDonor = async ({ authToken, routerContext, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;
    const { scholarshipId } = routerContext.query;

    return {
        ...accountData,
        scholarshipId,
        ...await API.fetchApplications({ token: authToken , accountId, scholarshipId }) };
}

/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getApplicationPageDataForReviewer = async ({ authToken, currentAccountId, routerContext }) => {
    const accountData = await getReviewerData(authToken, currentAccountId);
    const { accountId } = accountData;
    const { scholarshipId } = routerContext.query;

    return {
        ...accountData,
        scholarshipId,
        ...await API.fetchApplications({ token: authToken, accountId, scholarshipId, userContext: USER_CONTEXT.REVIEWER })
    };
}

/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getApplicationDetailsPageDataForReviewer = async ({ authToken, currentAccountId, routerContext }) => {
    const accountData = await getReviewerData(authToken, currentAccountId);
    const { accountId } = accountData;
    const { scholarshipId, applicationId } = routerContext.query;
    const applicationScore = await API.fetchApplicationScores({ token: authToken, accountId, applicationId, scholarshipId, userContext: USER_CONTEXT.REVIEWER })
    return {
        ...accountData,
        applicationScore,
        ...await API.fetchApplicationDetails({ token: authToken, accountId, applicationId, scholarshipId, userContext: USER_CONTEXT.REVIEWER })
    };
}

/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getApplicationDetailsPageDataForReviewerIFrame = async ({ authToken, routerContext, userContext }) => {
    const { scholarshipId, applicationId, accountId } = routerContext.query;
    var accountData = {}
    if(userContext === "REVIEWER"){
        accountData = await getReviewerData(authToken, accountId);    
    }else{
        accountData = await getDonorData(authToken, accountId);
    }    
    const applicationScore = []
    return {
        ...accountData,
        applicationScore,
        ...await API.fetchApplicationDetails({ token: authToken, accountId, applicationId, scholarshipId, userContext: userContext })
    };
}

/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getApplicationDetailsPageDataForDonor = async ({ authToken, currentAccountId, routerContext }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;
    const { scholarshipId, applicationId } = routerContext.query;
    const applicationScoreRounds = await API.fetchApplicationScoreRounds({ token: authToken, accountId, applicationId, scholarshipId, userContext: USER_CONTEXT.DONOR })
    return {
        ...accountData,
        ...await API.fetchApplicationDetails({ token: authToken, accountId, applicationId, scholarshipId, userContext: USER_CONTEXT.DONOR }),
        applicationScoreRounds
    };
}

/**
 * Fetch data for a Donor's account page
 * @param {string} authToken
 * @param {string} currentAccountId
 */
const getAccountPageDataForDonor = async ({ authToken, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    // Retrieve notification setting and scholarships
    const data = await Promise.all([
        API.fetchScholarships({ token: authToken, accountId }),
        // API.fetchAccountNotificationSetting({ token: authToken })
    ])

    // TODO: Replace with proper Donor Account API data fetch
    return { ...accountData, ...data[0], ...data[1] };
}

/**
 * Fetch data for a Reviewer's account page
 * @param {string} authToken
 * @param {string} currentAccountId
 */
const getAccountPageDataForReviewer = async ({ authToken, currentAccountId }) => {
    const accountData = await getReviewerData(authToken, currentAccountId);
    const { accountId } = accountData;

    // let notification = await API.fetchAccountNotificationSetting({ token: authToken })

    // TODO: Replace with proper Reviewer Account API data fetch
    return { 
        ...accountData, 
        // ...notification, 
        ...await API.fetchScholarships({ token: authToken, accountId }) 
    };
}

/**
 * Fetch data for a Donor's applicants page
 * @param {string} authToken
 * @param {string} currentAccountId
 */
const getApplicantsPageDataForDonor = async ({ authToken, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData, ...await API.fetchApplicants({ token: authToken , accountId }) };
}

/**
 * Fetch data for a Donor's people page
 * @param {string} authToken
 * @param {string} currentAccountId
 */
const getPeoplePageDataForDonor = async ({ authToken, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData, ...await API.fetchPeople({ token: authToken , accountId }) };
}

const getReportsPageDataForDonor = async ({ authToken, currentAccountId = '' }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData, ...await API.fetchClientReports({ token: authToken , accountId }) };
}

const getDisbursementPageDataForDonor = async ({ authToken, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData, ...await API.fetchClientDisbursements({ token: authToken , accountId }) };
}


/**
 * Fetch data for a Donor's scholarship create page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getNewScholarshipPageDataForDonor = async ({ authToken, currentAccountId }) => {
    const accountData = await getDonorData(authToken, currentAccountId);

    return { ...accountData };
}

/**
 * Fetch data for a Donor's scholarship edit page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 * @returns {Promise<{}>}
 */
const getEditScholarshipPageDataForDonor = async ({ authToken, currentAccountId, routerContext }) => {
    const accountData = await getDonorData(authToken, currentAccountId);
    const { accountId } = accountData;
    const { scholarshipId } = routerContext.query;
    const editScholarshipData = await API.fetchEditScholarship({ token: authToken, accountId, scholarshipId });

    return { ...accountData, editScholarshipData };
}

/**
 * Fetch data for a Recommender's home page.
 * @param {string} authToken
 * @returns {Promise}
 */
 const getProfileHerokuRole = async (authToken) => {
    let userData = await API.fetchProfile({ token: authToken })
    let heroku_role = [];
    heroku_role = (userData.user) && (userData.user.heroku_role__c) && userData.user.heroku_role__c.split(';')
    return {"heroku_role" : heroku_role, userInfo: userData.user}
}
/**
 * Fetch data for a Recommender's home page.
 * @param {string} authToken
 * @returns {Promise}
 */
const getHomePageAdmin = async (authAdminToken) => {
    const { user, error } = await API.fetchProfile({ token: authAdminToken.authAdminToken })

    if(error === "Not Authorized"){
        return {notification: [], statusCode: 503 }
    }
    let heroku_role = [];
    heroku_role = (user) && (user.heroku_role__c) && user.heroku_role__c.split(';')
    return {"heroku_role" : heroku_role, userInfo: user, statusCode: 200, notifications: []}
}

/**
 * Fetch data for a Recommender's home page.
 * @param {string} authToken
 * @returns {Promise}
 */
const getHomePageDataForRecommender = async ({ authToken }) => {

    // const { notification, error } = await API.fetchAccountNotificationSetting({ token: authToken })

    // if(error === "Not Authorized"){
    //     return {notification: [], statusCode: 503 }
    // }

    let heroku_role = await getProfileHerokuRole(authToken)
    return {
        ...await getNotifications(authToken),
        ...heroku_role,
        // ...notification,
        ...await API.fetchApplicantRecommendations({ token: authToken, userContext: USER_CONTEXT.REVIEWER })
    };
}

/**
 * Fetch data for a Recommender's home page.
 * @param {string} authToken
 * @returns {Promise}
 */
const getHomePageDataForUniversityStaff = async ({ authToken, userContext }) => {
    // const { notification, error } = await API.fetchAccountNotificationSetting({ token: authToken })
    // if(error === "Not Authorized"){
    //     return {notification: [], statusCode: 503 }
    // }
    let heroku_role = await getProfileHerokuRole(authToken)
    return {
        ...await getNotifications(authToken),
        ...heroku_role,
        // ...notification,
        ...await API.fetchApplicationsForUniversityStaff({ token: authToken, userContext: userContext })
    };
}


/**
 * Fetch data for a Recommender Recommendation Page.
 * @param {string} authToken
 * @returns {Promise}
 */
const getRecommendationPageDataForRecommender = async ({ authToken, routerContext, currentAccountId= "", pageType="" }) => {
    let accountData = {}
    if (pageType === PAGE_TYPES.DONOR.RECOMMENDATION_QUESTION_UPDATE) {
        accountData = await getDonorData(authToken, currentAccountId);
    }   
    const { recommendationid } = routerContext.query;
    const { applicant_recommendations, recommendation_questions, error } = await API.fetchApplicantRecommendationQuestions({ token: authToken, recommendationid });
    if(error === "Not Authorized"){
        return { statusCode: 503 }  
    }
    return {
        ...accountData,
        applicant_recommendations,
        recommendation_questions,
        statusCode: 200
    };
}

/**
 * Fetches the account data for Applicant.
 * @returns {Promise<{accountId: *, accounts: ({errorMessage: *, error: boolean}|*)}>}
 */
const getMemberInfo = async (authToken, userContext, currentAccountId) => {
    const { memberships = [], section_visibility_options, error } = await API.fetchAccounts({ token: authToken, userContext, currentAccountId });
    if(error === "Not Authorized"){
        return { statusCode: 503 }
    }
    let account;
    // If we have a currentAccountId context, try to use that account
    // Otherwise, just use the first account
    if (!currentAccountId) {
        if(memberships && memberships.length>0){
            account = memberships[0];
        }
    } else {
        account = memberships &&  memberships.find(a => a.sfid === currentAccountId);
    }
    account = account || (memberships && memberships[0]);
    const accountId = (account && account.sfid) && account.sfid
    let accounts = memberships
    return { accounts, account, accountId, section_visibility_options, statusCode: 200 }
};

/**
 * Gets base data for a Applicant.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getApplicantData = async (authToken, currentAccountId) => {
    const data = await Promise.all([
        getMemberInfo(authToken, USER_CONTEXT.APPLICANT, currentAccountId)
    ]);
    return {
        ...data[0]
    }
}


/**
 * Fetch data for a Donor's Scholarship page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getPageDataForApplicant = async ({ authToken, currentAccountId = '', routerContext }) => {
    const accountData = await getApplicantData(authToken, currentAccountId);
    const marketPlaceData = await getAccountDetails({ routerContext });
    let heroku_role = await getProfileHerokuRole(authToken)
    
    return { ...accountData, ...heroku_role, marketPlaceData };
}

/**
 * Fetch data for a Donor's Scholarship page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
 const getPageDataForScholarship = async ({ authToken, currentAccountId = '', routerContext }) => {
    const accountData = await getApplicantData(authToken, currentAccountId);
    const marketPlaceData = await getAccountDetails({ routerContext });
    let heroku_role = await getProfileHerokuRole(authToken)
    const { scholarshipId } = routerContext.query;
    let scholarshipDetail = await API.fetchScholarshipById({ token: authToken, scholarshipId });
    return { ...accountData, ...heroku_role, marketPlaceData, scholarshipDetail };
}

/**
 * Fetch data for a Donor's Scholarship page.
 * @param {string} authToken
 * @param {string} currentAccountId
 * @returns {Promise<{}>}
 */
const getApplicationData = async ({ authToken, currentAccountId = '' }) => {
    const accountData = await getApplicantData(authToken, currentAccountId);
    const { accountId } = accountData;

    return { ...accountData };
}



/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getApplicationResumePageData = async ({ authToken, currentAccountId, routerContext }) => {
    const accountData = await getApplicantData(authToken, currentAccountId);
    const { accountId } = accountData;
    let heroku_role = await getProfileHerokuRole(authToken)
    const { scholarshipId, applicationId, submit } = routerContext.query;
    let actionSubmit = (submit) ? submit : ''
    return {
        ...accountData,
        ...heroku_role,
        ...await API.fetchResumeScholarship({ token: authToken, scholarshipId, applicationId, actionSubmit })
    };
}


/**
 * Fetch data for a Reviewer's Applications/Single Scholarship page
 * @param {string} authToken
 * @param {string} currentAccountId
 * @param {object} routerContext
 */
const getStaffApplicationSummaryPageData = async ({ authToken, routerContext, userContext }) => {

    // let notification = await API.fetchAccountNotificationSetting({ token: authToken })
    let heroku_role = await getProfileHerokuRole(authToken)
    const { scholarshipId } = routerContext.query;
    let applicationId = scholarshipId

    return {
        // ...notification,
        ...heroku_role,
        ...await API.fetchStaffApplicationSummary({ token: authToken, applicationId, userContext })
    };
}


/**
 * Fetch user access token with SSO code
 * @param {object} routerContext
 */
const getTokenFromSSOCode = async ({ routerContext }) => {
    const { code } = routerContext.query;

    return {
        ...await API.authenticateViaSso({ code })
    };
}